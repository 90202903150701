import React, { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Lazy-loaded components
const ScrollToTop = lazy(() => import('./helpers/ScrollToTop'));
const NSMSticky = lazy(() => import('./NSMSticky'));
const Home = lazy(() => import("./pages/Home"));
const Service = lazy(() => import("./pages/Service"));
const SingleService = lazy(() => import("./pages/SingleService"));
const SingleBlog = lazy(() => import("./pages/SingleBlog"));
const Blog = lazy(() => import("./pages/Blog"));
const Gallery = lazy(() => import("./pages/Gallery"));
const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));
const Sitemap = lazy(() => import("./components/Sitemap"));
const FAQs = lazy(() => import("./pages/FAQs"));
const NotFound = lazy(() => import("./pages/NotFound"));
const Spinner = lazy(() => import("./components/Spinner/Spinner"));

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 3000); // Simulate loading for 3 seconds
    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <Suspense fallback={<div className="spinner">
    <div className="double-bounce1"></div>
    <div className="double-bounce2"></div>
  </div>}>
        {loading ? (
          <div className="spinner-wrapper">
            <Spinner />
          </div>
        ) : (
          <ScrollToTop>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/about" component={About} />
              <Route exact path="/services" component={Service} />
              <Route path="/services/:serviceTitle" component={SingleService} />
              <Route path="/blog/:blogTitle" component={SingleBlog} />
              <Route path="/blog" component={Blog} />
              <Route path="/gallery" component={Gallery} />
              <Route path="/contact" component={Contact} />
              <Route path="/sitemap" component={Sitemap} />
              <Route path="/faqs" component={FAQs} />
              <Route component={NotFound} />
            </Switch>
            <NSMSticky />
          </ScrollToTop>
        )}
      </Suspense>
    </Router>
  );
};

export default App;
